import { ChargersService } from '@core-shared/services/chargers/chargers.service';
import { GroupsService } from '@core-shared/services/groups/groups.service';
import { PanelsService } from '@core-shared/services/panels/panels.service';
import { BreadcrumbService } from '@core-shared/services/breadcrumb/breadcrumb.service';
import { SitesService } from '@core-shared/services/sites/sites.service';
import { OrganizationsService } from '@core-shared/services/organizations/organizations.service';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  static readonly ICON_DATA_BREADCRUMB = 'icon';
  static readonly SIDEMENU_DATA = 'sideMenu';
  private readonly crumbLabelsOrganizations: String[] = ['Sites', 'Org Configuration'];
  private readonly crumbLabelsSites: String[] = ['Chargers', 'Feeder Groups', 'Rebate Reports', 'Panels', 'Dashboard', 'Site Configuration'];
  private readonly crumbLabelsPanels: String[] = ['Panel Dashboard', 'Configuration', 'Panel Chargers'];
  private readonly crumbLabelsGroups: String[] = ['Group Dashboard', 'Group Configuration', 'Group Panels', 'Energy Defaults', 'Energy Schedules'];
  private readonly crumblLabelsChargers: String[] = ['Charger Dashboard', 'Charger Configuration', 'OCPP', 'Integration']

  menuItems!: MenuItem[];
  constructor(private activatedRoute: ActivatedRoute, private orgService: OrganizationsService, private panelService: PanelsService,
              private siteService: SitesService, private groupService: GroupsService, private chargerService: ChargersService,
              private router: Router, private breadcrumbService: BreadcrumbService) {
                this.router.events.subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root))
                this.breadcrumbService.getEvent().subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root))
              }

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root)
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      let crumb = child.snapshot.data[BreadcrumbsComponent.ROUTE_DATA_BREADCRUMB];
      // Do not delete this line -> can be used to add icons if required later
      // const icon = child.snapshot.data[BreadcrumbsComponent.ICON_DATA_BREADCRUMB];
      const disabled = true;
      const target = '_parent'
      if (crumb != null) {
        let label = crumb.value;
        breadcrumbs.push({label, url, target});
        if (this.crumbLabelsOrganizations.includes(crumb.label)) {
          label = this.orgService.organizationName;
          label = !label?.trim() ? 'N/A': label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0, {label , url, target, disabled});
          }
        } else if (this.crumbLabelsSites.includes(crumb.label)) {
          label = this.siteService.siteName;
          label = !label?.trim() ? 'N/A': label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0, {label , url, target, disabled});
          }
        } else if (this.crumbLabelsPanels.includes(crumb.label)) {
          label = this.panelService.panelName;
          label = !label?.trim() ? 'N/A': label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0, {label , url, target, disabled});
          }
        } else if (this.crumbLabelsGroups.includes(crumb.label)) {
          label = this.groupService.groupName;
          label = !label?.trim() ? 'N/A': label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0, {label , url, target, disabled});
          }
        } else if (this.crumblLabelsChargers.includes(crumb.label)) {
          label = this.chargerService.chargerName;
          label = !label?.trim() ? 'N/A': label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0, {label , url, target, disabled});
          }
        }

      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }

}
