import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { StateService } from '@core-shared/services/state/state.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  currentDate: Date = new Date();
  startDate: Date = new Date(new Date().setHours(new Date().getHours() - 3));
  endDate: Date = new Date();

  siteCurrentDate: Date = new Date();
  siteStartDate: Date = new Date(new Date().setHours(new Date().getHours() - 3));
  siteEndDate: Date = new Date();

  @Input() frequency: any[] = [
    {label: 'Last 1 Hour', value: 1},
    {label: 'Last 3 Hour', value: 3},
    {label: 'Last 6 Hour', value: 6},
    {label: 'Last 12 Hour', value: 12},
    {label: 'Last Day', value: 24},
    {label: 'Last Week', value: 168},
    {label: 'Last Month', value: 672},
    {label: 'Custom', value: 'custom'},
  ];
  selectedFrequency: any = 3;
  timeZoneOptions = [
    {label: 'Local Timezone', value: 'local-timezone'},
    {label: 'Site Timezone', value: 'site-timezone'},
  ]
  selectedTimeZone = 'site-timezone';
  disableDate = true;
  selectedRefresh = false;
  spinner = false;
  refreshTimeout: any;
  startOffset!: number;
  endOffset!: number;
  @Output() dateEmit = new EventEmitter<any>();
  @Output() timeZoneEmit = new EventEmitter<any>();
  constructor(private stateService: StateService) {
    this.changeAlternateTimezoneDate();
  }

  ngOnInit(): void {
  }

  changeAlternateTimezoneDate() {
    this.siteStartDate = this.startDate;
    this.siteEndDate = this.endDate;
    this.siteStartDate = new Date(this.siteStartDate.toLocaleString("en-US", {
      timeZone: this.stateService.siteTimezone
    }))
    this.siteEndDate = new Date(this.siteEndDate.toLocaleString("en-US", {
      timeZone: this.stateService.siteTimezone
    }))
    this.startOffset = this.siteStartDate.getHours() - this.startDate.getHours()
    this.endOffset = this.siteEndDate.getHours() - this.endDate.getHours()
  }

  alterTimezone() {
    this.timeZoneEmit.emit({startDate: this.startDate, selectedTimeZone: this.selectedTimeZone === 'local-timezone' ? 0 : 1});
  }

  alterFrequency() {
    if (this.selectedFrequency === 'custom') {
      this.startDate = new Date(new Date().setHours(new Date().getHours() - 1))
      this.endDate = new Date();
      this.disableDate = false;
      this.selectedRefresh = false;
    } else {
      this.startDate = new Date(new Date().setHours(new Date().getHours() - this.selectedFrequency))
      this.endDate = new Date();
      this.disableDate = true;
    }

    this.dateEmit.emit({startDate: this.startDate, endDate: this.endDate});
    this.changeAlternateTimezoneDate();
    this.alterTimezone();
  }

  changeStartDate(e: any) {
    if (new Date(e) > this.endDate) {
      const eDate = new Date(e);
      eDate.setHours(eDate.getHours() + 1);
      this.endDate = eDate;
    }
    this.changeAlternateTimezoneDate();
    this.emitDate(this.startDate, this.endDate);
    this.timeZoneEmit.emit({startDate: this.startDate, selectedTimeZone: 0});
  }

  changeAlternateStartDate(e: any) {
    if (new Date(e) > this.siteEndDate) {
      const eDate = new Date(e);
      eDate.setHours(eDate.getHours() + 1);
      this.siteEndDate = eDate;
      this.endDate = eDate
      this.endDate = new Date(this.endDate.setHours(this.endDate.getHours() - this.endOffset));
    }
    this.startDate = new Date(e);
    this.startDate = new Date(this.startDate.setHours(this.startDate.getHours() - this.startOffset));
    this.emitDate(this.startDate, this.endDate);

    this.timeZoneEmit.emit({startDate: this.startDate, selectedTimeZone: 1});
  }

  changeEndDate(e: any) {
    this.endDate = new Date(e);
    this.changeAlternateTimezoneDate();
    this.emitDate(this.startDate, this.endDate);
  }

  changeAlternateEndDate(e: any) {
    this.siteEndDate = new Date(e);
    this.endDate = new Date(e);
    this.endDate = new Date(this.endDate.setHours(this.endDate.getHours() - this.endOffset));
    this.emitDate(this.startDate, this.endDate);
  }

  emitDate(startDate: Date, endDate: Date) {
    this.dateEmit.emit({startDate: startDate, endDate: endDate});
  }

  changeRefresh() {
    if (this.selectedRefresh) {
      this.alterFrequency()
      this.resetSpinner();
    } else {
      this.stopTimer()
    }
  }

  resetSpinner() {
    this.spinner = true;
    setTimeout(() => {
     this.spinner = false;
    }, 1000);
    this.refreshTimeout = setTimeout(() => this.changeRefresh(), 60000);
  }

  stopTimer() {
    clearTimeout(this.refreshTimeout);
  }
}
