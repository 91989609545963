import { Charger, ChargerStatuses } from '@core-shared/models/charger.model';
import { Panel } from '@core-shared/models/panel.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map, Observable } from 'rxjs';
import { Group } from '@core-shared/models/group.model';
import { Site } from '@core-shared/models/site.model';
import { Fault } from '@core-shared/models/fault.model';
import { TelemetryQuery } from '@core-shared/models/telemetry-query.model';
import { changeChargerStatuses } from '@core-shared/components/charger-status-modifier/charger-status-modfier';

@Injectable({
  providedIn: 'root'
})
export class SitesService {
  baseUrl = `${environment.apiEndpoint}/sites`

  constructor(private http: HttpClient) { }

  get siteName(): string | null {
    return sessionStorage.getItem('siteName');
  }

  setSiteName(name: string) {
    sessionStorage.setItem('siteName', name);
  }

  getSites():Observable<Site[]> {
    return this.http.get<Site[]>(`${this.baseUrl}`)
  }

  getSite(siteId: string): Observable<Site> {
    return this.http.get<Site>(`${this.baseUrl}/${siteId}`)
  }

  updateSite(site: Site): Observable<Site> {
    return this.http.put<Site>(`${this.baseUrl}`, site);
  }

  getGroups(siteId: string): Observable<Group[]> {
    return this.http.get<Group[]>(`${this.baseUrl}/${siteId}/groups`);
  }

  getPanels(siteId: string): Observable<Panel[]> {
    return this.http.get<Panel[]>(`${this.baseUrl}/${siteId}/panels`)
  }

  getChargers(siteId: string): Observable<Charger[]> {
    return this.http.get<Charger[]>(`${this.baseUrl}/${siteId}/chargers`)
  }

  getChargerStatusOverview(siteId: string): Observable<ChargerStatuses[]> {
    return this.http.get<ChargerStatuses[]>(`${this.baseUrl}/${siteId}/charger-statuses`)
  }

  getChargerStatusOverviewWithStatusOverride(siteId: string): Observable<ChargerStatuses[]> {
    return this.http.get<ChargerStatuses[]>(`${this.baseUrl}/${siteId}/charger-statuses`)
    .pipe(
      map(x => changeChargerStatuses(x))
      )
  }

  getSiteConnectivity(siteId: string) {
    return this.http.get<boolean>(`${this.baseUrl}/${siteId}/connectivity`);
  }

  getSiteActivePower(siteId: string): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/${siteId}/active-power`)
  }

  getSiteAverageChargeDuration(siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/average-charge-duration`, data);
  }

  getSiteEnergyUsage(siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/energy-usage`, data);
  }

  getSiteSessionCount(siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/session-count`, data);
  }

  getSiteUptime(siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/uptime`, data);
  }

  /**
   * Indicates if the site has any chargers with a fault as their last reported status.
   * @param siteId
   * @returns true if the site has any chargers with a fault as their last reported status otherwise false.
   */
  getSiteHasFaults(siteId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/${siteId}/faulted`);
  }

  /**
   * Returns a list of chargers with a fault as their last reported status for this site.
   * @param siteId The site identifier.
   * @returns an array of chargers with a fault as their last reported status.
   */
  getSiteFaults(siteId: string, data: any): Observable<Fault[]> {
    return this.http.post<Fault[]>(`${this.baseUrl}/${siteId}/faults`, data);
  }

  getSiteTimezone(siteId: string): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/${siteId}/time-zone`)
  }
/**
 *
 * @returns a list of all postgresql time zones
 */
  getSiteTimezones(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/time-zones`)
  }

  getSiteStations(siteId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${siteId}/stations`)
  }
}
