import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-tile',
  templateUrl: './status-tile.component.html',
  styleUrls: ['./status-tile.component.scss']
})
export class StatusTileComponent implements OnInit {
  @Input() statusText!: string;
  @Input() unit!: string;
  @Input() count!: number | string;
  @Input() style  = {};
  @Input() type: string = 'success';
  constructor() { }

  ngOnInit(): void {
  }

}
