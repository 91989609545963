import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormErrors} from './form-errors.enum';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit {
  @Input() control!: UntypedFormControl | UntypedFormGroup | AbstractControl;
  @Input() error?: string;
  status!: 'VALID' | 'INVALID';
  formError!: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.formError = this.control instanceof UntypedFormGroup;
    this.control.statusChanges.subscribe((status) => {
      if (status === 'INVALID') {
        this.error = this.getErrorMessage();
      }
    });
  }

  getErrorMessage(): any {
    if (!this.control.errors) {
      return null;
    }
    const topError = Object.keys(this.control.errors).shift();
    switch (topError) {
      case FormErrors.Email:
        return 'Enter a valid email address';
      case FormErrors.Required:
        return 'This field is required';
      case FormErrors.MaxLength:
        return `Max length is ${this.control.errors[FormErrors.MaxLength].requiredLength}`;
      case FormErrors.MinLength:
        return `Min length is ${this.control.errors[FormErrors.MinLength].requiredLength}`;
      case FormErrors.Max:
        return `Max value is ${this.control.errors[FormErrors.Max].max}`;
      case FormErrors.Min:
        return `Min value is ${this.control.errors[FormErrors.Min].min}`;
      case FormErrors.Pattern:
        return `Field must match pattern: ${this.control.errors[FormErrors.Pattern].requiredPattern}`;
      case FormErrors.Custom:
        return this.control.errors[FormErrors.Custom];
    }
  }

}
