import { Component, OnInit } from '@angular/core';
import { AuthService } from '@global/services/auth/auth.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserInitialSetupService } from '../../services/user-initial-setup/user-initial-setup.service';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent implements OnInit {
  eulaContent!: any;
  eulaIsAccepted!: boolean;

  constructor(public ref: DynamicDialogRef, private setupService: UserInitialSetupService, private authService: AuthService) { }

  ngOnInit(): void {
    this.eulaIsAccepted = this.authService.eula;
    this.setupService.getEula()
    .then((resp) => {
      this.eulaContent = resp;
    })
  }

  accept() {
    this.ref.close(true);
  }

  reject() {
    this.ref.close(false);
  }

}
