import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthService } from '@global/services/auth/auth.service';
import { StateService } from '@core-shared/services/state/state.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'core',
    canLoad: [AuthService],
    canActivateChild: [StateService],
    loadChildren: () => import('./core-module/core.module').then(c => c.CoreModule),
  },
  {
    path: '500',
    component: ServerErrorComponent,
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
