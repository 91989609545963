import { StateService } from '@core-shared/services/state/state.service';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '@global/services/auth/auth.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  static readonly ROUTE_DATA_SIDEMENU = 'sideMenu';
  public organizationId!: string;
  public siteId!: string;
  public panelId!: string;
  public groupId!: string;
  public chargerId!: string;
  menuItems!: MenuItem[];
  constructor(private stateService: StateService, private auth: AuthService) {
    this.stateService.organizationId.subscribe((id) => {this.organizationId = id;})
    this.stateService.siteId.subscribe((id) => {this.siteId = id;})
    this.stateService.panelId.subscribe((id) => {this.panelId = id;})
    this.stateService.groupId.subscribe((id) => {this.groupId = id;})
    this.stateService.chargerId.subscribe((id) => {this.chargerId = id;})
    this.stateService.sideMenu.subscribe((menuItem) => {
       this.menuItems = menuItem;
       this.addUrls();
     })
  }

  ngOnInit(): void {
  }

  addUrls() {
    this.menuItems.forEach((entry) => {
      entry.title === 'Organizations' ? entry.url = 'organization/':
      entry.title === 'Site Map' ? entry.url = 'site-map/':
      entry.title === 'Manufacturing' && this.auth.superUser? (entry.url = 'manufacturing/') && (entry.disabled = false):
      entry.title === 'Manufacturing' && !this.auth.superUser? (entry.url = 'manufacturing/') && (entry.disabled = true):
      entry.title === 'Change Password' ? entry.url = 'user/change-password':
      entry.title === 'Profile' ? entry.url = 'user/profile':
      entry.title === 'Chargers' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/chargers':
      entry.title === 'Site Configuration' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/site-configuration':
      entry.title === 'Feeder Groups' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders':
      entry.title === 'Dashboard' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/dashboard':
      entry.title === 'Panel Dashboard' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/panel-dashboard':
      entry.title === 'Configuration' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/configuration':
      entry.title === 'Panel Chargers' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/panel-chargers':
      entry.title === 'Panels' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels':
      entry.title === 'Rebate Reports' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/rebate-reports':
      entry.title === 'Sites' ? entry.url = 'organization/' + this.organizationId + '/sites/':
      entry.title === 'Group Dashboard' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders/' + this.groupId + '/group-dashboard':
      entry.title === 'Group Configuration' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders/' + this.groupId + '/group-configuration':
      entry.title === 'Group Panels' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders/' + this.groupId + '/group-panels':
      entry.title === 'Energy Schedules' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders/' + this.groupId + '/energy-schedules':
      entry.title === 'Energy Defaults' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders/' + this.groupId + '/energy-defaults':
      entry.title === 'Charger Dashboard' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/chargers/' + this.chargerId + '/charger-dashboard':
      entry.title === 'Charger Configuration' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/chargers/' + this.chargerId + '/charger-configuration':
      entry.title === 'OCPP' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/chargers/' + this.chargerId + '/charger-ocpp':
      entry.title === 'Integrations' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/chargers/' + this.chargerId + '/charger-integrations':
      entry.title === 'Users' ? entry.url = 'organization/' + this.organizationId + '/users':
      entry.title === 'Org Configuration' ? entry.url = 'organization/' + this.organizationId + '/configuration': entry.url = '';
    } )
  }
}
