<div class="main">
  <textarea *ngIf="eulaContent" readonly [value]="eulaContent" [disabled]="true" pInputTextarea  rows="30" cols="40"></textarea>
</div>
<br/>
<br/>
<br/>
<ng-container *ngIf="!eulaIsAccepted">
  <div class="button-group">
    <button (click)="accept()" pButton label="Accept"></button>
    <button (click)="reject()" pButton class="p-button-secondary" label="Reject"></button>
  </div>
</ng-container>
<p></p>
<p></p>
<p></p>
<p></p>
