import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import Boost from 'highcharts/modules/boost';
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

window.moment = Moment;
mTZ();
Boost(Highcharts);
@Component({
  selector: 'app-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss']
})
export class ChartWidgetComponent implements OnInit {
  @Input() Highcharts: typeof Highcharts = Highcharts; // required
  @Input() style = {};
  @Input() chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  @Input() chartOptions!: Highcharts.Options; // required
  @Input() chartCallback!: Highcharts.ChartCallbackFunction; // optional function, defaults to null
  @Input() updateFlag: boolean = false; // optional boolean
  @Input() oneToOneFlag: boolean = true; // optional boolean, defaults to false
  @Input() runOutsideAngular: boolean = false; // optional boolean, defaults to false
  @Input() styleSettings = {'width': '97.8%', 'height': '400px','display': 'block','margin-left': '15px', 'margin-right': '15px'}
  constructor() { }

  ngOnInit(): void {
  }

}
