import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '@global/services/notification/notification.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-in-place-edit-field',
  templateUrl: './in-place-edit-field.component.html',
  styleUrls: ['./in-place-edit-field.component.scss']
})
export class InPlaceEditFieldComponent implements OnInit, OnChanges {
  @Input() canEdit = true;
  @Input() label!: string;
  @Input() value: any;
  @Input() tableValue: any;
  @Input() object!: object;
  @Input() width = '100%';
  @Input() placeHolder = '';
  @Input() showLabel = true;
  // Let's not use the approach of using a boolean to determine what type should be.
  // If a promise or observable is an acceptable value
  // then make use of type narrowing to determine what the type is.
  // eg: if (foo instanceof Promise<any>)
  // else if (foo instance of Observable<any>)
  @Input() promise = true;
  @Output() valueChange = new EventEmitter<any>();
  @Input() callback!: (e: string) => Promise<any> | any;
  originalValue!: string;
  constructor(private notify: NotificationService) { }

  ngOnInit(): void {
    this.originalValue = this.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.originalValue = changes.value.currentValue;
  }

  save(): Promise<any> | any {
    if (this.value === this.originalValue) {
      return Promise.resolve();
    }
    if (this.promise) {
      return this.callback(this.tableValue || this.value)
        .then(() => {
          this.originalValue = this.value;
        })
        .catch((err: HttpErrorResponse) => {
          this.value = this.originalValue;
          this.notify.showError(`Error Updating ${this.label}: ${err.error}`);
        });
    } else {
    //   // Not resetting value on error
    //   // This will never error here.
      return of(this.callback(this.tableValue || this.value))
    }
  }

  handleEnterKey(input: HTMLInputElement) {
    if (input.readOnly) {
      input.readOnly = false;
    } else {
      if (this.save().then) {
        this.save().then(() => {
        input.readOnly = true;
      });
      }
    }
  }

}
