import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss']
})
export class WidgetContainerComponent implements OnInit {
  @Input() header!: string;
  @Input() footer!: string;
  @Input() alignWithHeader = false;
  constructor() { }

  ngOnInit(): void {
  }

}
