<div class="phone-input flex-column">
  <label [class]="required ? 'float low form-label required-asterisk' : 'float low form-label'">{{label}}</label>
  <div class="flex-row">
    <p-dropdown id="phone-dropdown" [options]="countries" [(ngModel)]="selectedCountry" optionLabel="name" [filter]="true" filterBy="name" (onChange)="emitOutput()">
      <ng-template pTemplate="selectedItem">
          <div class="country-item country-item-value flex-row" *ngIf="selectedCountry">
              <span [class]="'flag-icon flag-icon-' + selectedCountry.code.toLowerCase() + ' flag-icon-squared'"></span>
              <div>&nbsp;{{selectedCountry.ext}}</div>
          </div>
      </ng-template>
      <ng-template let-country pTemplate="item">
          <div class="country-item flex-row" [id]="country.code">
              <span [class]="'flag-icon flag-icon-' + country.code.toLowerCase() + ' flag-icon-squared'"></span>
              <div>&nbsp;{{country.name}}</div>
          </div>
      </ng-template>
    </p-dropdown>
    <input id="phone-input" type="text" [(ngModel)]="newPhoneNumber" (change)="emitOutput()" (keyup)="emitOutput()"/>
  </div>
  <small *ngIf="errorFlag">{{errorMessage}}</small>
</div>
