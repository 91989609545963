<ol>
  <div *ngFor="let option of menuItems">
    <ng-container *ngIf="option.label === 'Users'; else elseBlock">
      <div *appFeatureControl="[6,5];type:'remove';id:organizationId">
        <li [id]="'side-menu-' + option.label" *ngIf="!option.disabled" [routerLink]="option.url" routerLinkActive="active">
          <a class="sidenav-link">
            <i [class]="option.icon"></i>
            <span [id]="option.label" class="hide-closed">{{option.label}}</span>
          </a>
        </li>
      </div>
    </ng-container>
    <ng-template #elseBlock>
      <li [id]="'side-menu-' + option.label" *ngIf="!option.disabled" [routerLink]="option.url" routerLinkActive="active">
        <a class="sidenav-link">
          <i [class]="option.icon"></i>
          <span [id]="option.label" class="hide-closed">{{option.label}}</span>
        </a>
      </li>
    </ng-template>
  </div>
</ol>

