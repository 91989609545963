<div class="margin screen-max-size flex-row space-between-center" ngClass.lt-md="flex-column space-between-center">
  <div class="flex-row center-center">
    <h4>Range</h4>
    <p-dropdown id="frequency" [options]="frequency" [(ngModel)]="selectedFrequency" optionLabel="label" optionValue="value" (onChange)="alterFrequency()"></p-dropdown>
    <p-checkbox id="auto-refresh-checkbox" class="ml-20" name="refresh" [disabled]="selectedFrequency === 'custom'" [binary]="true" [(ngModel)]="selectedRefresh" label="Auto Refresh" (onChange)="changeRefresh()"></p-checkbox>

    <i *ngIf="spinner" class="pi pi-spin pi-sync ml-20"></i>
  </div>
  <div class="flex-row center-center">
    <div *ngIf="selectedTimeZone === 'local-timezone'" [class]="disableDate ? 'flex-row disable center-center' : 'flex-row center-center'">
      <h4>From</h4>
      <p-calendar id="start-date-calendar-1" [(ngModel)]="startDate" [showTime]="true" [maxDate]="currentDate" (onSelect)="changeStartDate($event)" [readonlyInput]="true"></p-calendar>
      <h4 class="ml-20">To</h4>
      <p-calendar id="end-date-calendar-1" [(ngModel)]="endDate" [minDate]="startDate" [showTime]="true" (onSelect)="changeEndDate($event)" [readonlyInput]="true"></p-calendar>
    </div>
    <div *ngIf="selectedTimeZone === 'site-timezone'" [class]="disableDate ? 'flex-row disable center-center' : 'flex-row center-center'">
      <h4>From</h4>
      <p-calendar id="start-date-calendar-2" [(ngModel)]="siteStartDate" [showTime]="true" [maxDate]="siteCurrentDate" (onSelect)="changeAlternateStartDate($event)" [readonlyInput]="true"></p-calendar>
      <h4 class="ml-20">To</h4>
      <p-calendar id="end-date-calendar-2" [(ngModel)]="siteEndDate" [minDate]="siteStartDate" [showTime]="true" (onSelect)="changeAlternateEndDate($event)" [readonlyInput]="true"></p-calendar>
    </div>
    <div class="ml-20">
      <p-dropdown id="timezone-options" [options]="timeZoneOptions" [(ngModel)]="selectedTimeZone" optionLabel="label" optionValue="value" (onChange)="alterTimezone()"></p-dropdown>
    </div>

  </div>

</div>


