import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss']
})
export class PhoneNumberInputComponent implements OnInit, OnChanges {
  @Input() phNumber!: string;
  @Input() label!: string;
  @Input() required = false;
  @Output() emitPhone = new EventEmitter<any>();

  countries = [
    {name: 'United States', code: 'US', ext: '+1'},
    {name: 'South Korea', code: 'KR', ext: '+82'}
  ];
  selectedCountry = {name: 'United States', code: 'US', ext: '+1'}
  newPhoneNumber: string = '';
  errorMessage = '';
  errorFlag = false;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.phNumber) {
      this.phNumber = changes?.phNumber?.currentValue
      this.selectCountry();
    }
  }

  selectCountry() {
    if (this.phNumber?.includes('+1')) {
      this.newPhoneNumber = this.phNumber?.split('+1')[1];
      this.selectedCountry = {name: 'United States', code: 'US', ext: '+1'}
    } else if (this.phNumber?.includes('+82')) {
      this.newPhoneNumber = this.phNumber?.split('+82')[1];
      this.selectedCountry = {name: 'South Korea', code: 'KR', ext: '+82'}
    }
  }

  emitOutput() {
    const phone = this.selectedCountry.ext + this.newPhoneNumber;
    this.errorFlag = false;
    this.errorMessage = ''
    try{
      if ((this.selectedCountry.ext === '+1' && this.newPhoneNumber.length > 10) || phoneUtil.isValidNumberForRegion(phoneUtil.parse(this.newPhoneNumber, this.selectedCountry.code), this.selectedCountry.code) === false) {
        this.errorFlag = true;
        this.errorMessage = 'Invalid Phone Number';
      }
    } catch {
      this.errorFlag = true;
      this.errorMessage = 'Invalid Phone Number';
    }
    this.emitPhone.emit({ph: phone, error: this.errorFlag, message: this.errorMessage})
  }
}
