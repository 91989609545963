import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-overview',
  templateUrl: './status-overview.component.html',
  styleUrls: ['./status-overview.component.scss']
})
export class StatusOverviewComponent implements OnInit {
  @Input() value!: number | string;
  @Input() unit!: string;
  @Input() parameter!: string;
  constructor() { }

  ngOnInit(): void {
  }

}
