import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription, of } from 'rxjs';

@Component({
  selector: 'app-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss'],
  providers: []
})
export class FormContainerComponent implements OnDestroy {
  @Input() set formGroup(value: UntypedFormGroup) {
    if (value) {
      this.form = value;
      this.initAtomForm();
    }
  }

  @Input() submitFunc!: ((x: any) => Promise<any>) | null;
  @Input() submitFuncObs!: ((x: any) => any) | null;
  @Input() promise = true;
  @Input() submitLabel: 'Submit' | 'Apply' | 'Login' | 'Verify' | 'Move' | 'Create' = 'Submit';
  @Input() resetLabel: 'Reset' | 'Discard' = 'Reset';
  @Input() buttonPosition: 'flex-start' | 'flex-end' | 'center' | 'unset' = 'flex-end';
  @Input() resetToInitialValue!: boolean;
  @Input() switchEditButton!: boolean;
  @Input() switchEditButtonDisable!: boolean;
  @Input() customButton!: boolean;
  @Input() hasReset = true;
  @Input() style = {};
  @Output() submitted = new EventEmitter();
  @Output() discarded = new EventEmitter();
  @Output() changed = new EventEmitter();
  initialFormValue!: object;
  subscription!: Subscription;
  form!: UntypedFormGroup;

  constructor() {
  }

  initAtomForm(): void {
    this.initialFormValue = this.form.getRawValue();
    this.subscription = this.form.valueChanges.subscribe((value) => {
      this.changed.emit(value);
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  submit() {
    if (this.submitFunc || this.submitFuncObs) {
      this.promise ? this.submitAsPromise() : this.submitAsObservable();
    }
  }

  submitAsPromise() {
    if (this.form.valid && !this.switchEditButtonDisable && this.submitFunc) {
      this.submitted.emit(this.form.getRawValue());
      return this.submitFunc(this.form.getRawValue())?.then(() => {
        this.initialFormValue = this.form.getRawValue();
        this.form.markAsPristine();
      });
    }
  }

  submitAsObservable() {
    if (this.form.valid && !this.switchEditButtonDisable && this.submitFuncObs) {
      this.submitted.emit(this.form.getRawValue());
      return of(this.submitFuncObs(this.form.getRawValue()))?.subscribe({
        next: () => {
          this.initialFormValue = this.form.getRawValue();
          this.form.markAsPristine();
        }
      })
    }
  }

  reset() {
    if (this.resetToInitialValue) {
      this.form.reset(this.initialFormValue);
      this.discarded.emit(this.form.getRawValue());
      return;
    }
    this.form.reset();
    this.discarded.emit(this.form.getRawValue());
  }
}
