// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Third party
import { HighchartsChartModule } from 'highcharts-angular';
//Ours
// Components
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { EulaComponent } from './components/eula/eula.component';
import { InPlaceEditFieldComponent } from './components/in-place-edit-field/in-place-edit-field.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { WidgetContainerComponent } from './components/widget-container/widget-container.component';
import { FormErrorComponent } from './components/form-tools/form-error/form-error.component';
import { FormInputComponent } from './components/form-tools/form-input/form-input.component';
import { FormContainerComponent } from './components/form-tools/form-container/form-container.component';
import { ChartWidgetComponent } from './components/chart-widget/chart-widget.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
// Directives
import { FeatureControlDirective } from './directives/feature-control/feature-control.directive';
import { ChargerStatusDirective } from './directives/status/charger-status.directive';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { StatusTileComponent } from './components/status-tile/status-tile.component';
import { StatusOverviewComponent } from './components/status-overview/status-overview.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FaultIndicatorComponent } from './fault-indicator/fault-indicator.component';

// Modules
// Note the name is confusing but it is a module we created that contains primeng things that we are using.
import { PrimengModule } from './primeng.module';

// Services
import { EulaService } from './services/eula/eula.service';
import { CanDeactivateGuard } from './guards/can-component-deactivate.guard';
import { KebabCaseIdPipe } from './components/kebab-case-id-pipe/kebab-case-id-pipe'

@NgModule({
  declarations: [
    //Components
    FormContainerComponent,
    FormInputComponent,
    FormErrorComponent,
    EulaComponent,
    WidgetContainerComponent,
    PageContainerComponent,
    BreadcrumbsComponent,
    InPlaceEditFieldComponent,
    ChartWidgetComponent,
    SideMenuComponent,
    StatusTileComponent,
    StatusOverviewComponent,
    DatePickerComponent,
    FaultIndicatorComponent,
    PhoneNumberInputComponent,
    //Directives
    ChargerStatusDirective,
    FeatureControlDirective,
    //Pipe
    KebabCaseIdPipe
    ],
  imports: [
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    PrimengModule.forRoot()
  ],
  exports: [
    //Components
    BreadcrumbsComponent,
    ChartWidgetComponent,
    DatePickerComponent,
    FormContainerComponent,
    FaultIndicatorComponent,
    FormInputComponent,
    FormErrorComponent,
    InPlaceEditFieldComponent,
    PageContainerComponent,
    SideMenuComponent,
    StatusTileComponent,
    StatusOverviewComponent,
    WidgetContainerComponent,
    PhoneNumberInputComponent,
    //Directives
    FeatureControlDirective,
    ChargerStatusDirective,
    //Modules
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    //Pipe
    KebabCaseIdPipe
  ]
})
export class CoreSharedModule {
  constructor() { }

  static forRoot(): ModuleWithProviders<CoreSharedModule> {
    return {
      ngModule: CoreSharedModule,
      providers: [
        EulaService,
        CanDeactivateGuard
      ]
    };
  }
}
