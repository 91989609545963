<div id="top-nav" class="top-nav">
  <img src="assets/images/logo.svg" alt="Epurpl logo">
  <p class="site-manager"></p>
</div>
<div class="login-main">

  <p-card>
    <h2>Log In</h2>
    <div *ngIf="activeIndex == 0">
      <app-form-container
        *ngIf="userLoginForm"
        [formGroup]="userLoginForm"
        submitLabel="Login"
        [submitFunc]="submitLogin"
        [buttonPosition]="'center'"
        [hasReset]="false">
        <form [formGroup]="userLoginForm">
          <app-form-input label="Email Address" [control]="email">
            <input type="text" [formControl]="email"/>
          </app-form-input>
          <app-form-input label="Password" [control]="password">
            <input [type]="showPassword? 'text': 'password'" [formControl]="password"/>
            <span [class]="showPassword? 'eye pi pi-eye-slash': 'eye pi pi-eye'" (click)="showPassword = !showPassword"></span>
          </app-form-input>
        </form>
      </app-form-container>
      <p-footer>
        <div class="forgot-password">
          <a href="#/forgot-password">Forgot Password ?</a>
        </div>
      </p-footer>

    </div>
    <div *ngIf="activeIndex == 1">
      <form (ngSubmit)="submitTFA()">
        <p>Please enter the six digit code texted to you</p>
        <div class="form-group flex-column">
          <label for="verification-code">Verification Code</label>
          <br/>
          <ng-otp-input id="verification-code" (onInputChange)="onOtpChange($event)" [config]="{length:6, allowNumbersOnly: true, inputStyles: {display: 'inline'}}"></ng-otp-input>
        </div>
        <br/>

        <button pButton type="submit" label="Submit" class="p-button-raised" color="primary" [disabled]="otp.length < 6"></button>
        <p-footer>
        </p-footer>
      </form>
    </div>
  </p-card>
</div>
<app-footer></app-footer>
