import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Group, GroupConnectivity, GroupTelemetry, GroupUpdateDto } from '@core-shared/models/group.model';
import { Panel } from '@core-shared/models/panel.model';
import { Schedule } from '@core-shared/models/schedule.model';
import { ChargerStatuses } from '@core-shared/models/charger.model';
import { Fault } from '@core-shared/models/fault.model';
import { TelemetryQuery } from '@core-shared/models/telemetry-query.model';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  baseUrl = `${environment.apiEndpoint}/sites`

  constructor(private http: HttpClient) { }

  get groupName(): string | null {
    return sessionStorage.getItem('groupName');
  }

  setGroupName(name: string) {
    sessionStorage.setItem('groupName', name);
  }

  createGroup(group: GroupUpdateDto, siteId: string): Observable<Group> {
    return this.http.post<Group>(`${this.baseUrl}/${siteId}/groups`, group);
  }

  getSiteGroups(siteId: string): Observable<Group> {
    return this.http.get<Group>(`${this.baseUrl}/${siteId}/groups`)
  } 

  getGroup(groupId: string, siteId: string): Observable<Group> {
    return this.http.get<Group>(`${this.baseUrl}/${siteId}/groups/${groupId}`)
  }

  getGroupSwitches(groupId: string, siteId: string): Observable<Group> {
    return this.http.get<Group>(`${this.baseUrl}/${siteId}/groups/${groupId}/switches`)
  }

  getGroupConnectors(groupId: string, siteId: string): Observable<Group> {
    return this.http.get<Group>(`${this.baseUrl}/${siteId}/groups/${groupId}/connectors`)
  }

  getGroupConnectorsStatuses(groupId: string, siteId: string): Observable<Group> {
    return this.http.get<Group>(`${this.baseUrl}/${siteId}/groups/${groupId}/connector-statuses`)
  }

  getGroupMetrics(groupId: string, siteId: string): Observable<Group> {
    return this.http.get<Group>(`${this.baseUrl}/${siteId}/groups/${groupId}/metrics`)
  }

  updateGroup(group: GroupUpdateDto, siteId: string): Observable<Group> {
    return this.http.put<Group>(`${this.baseUrl}/${siteId}/groups/${group.id}`, group);
  }

  deleteGroup(groupId: string, siteId: string): Observable<unknown> {
    return this.http.delete(`${this.baseUrl}/${siteId}/groups/${groupId}`)
  }

  getPanels(groupId: string, siteId: string): Observable<Panel[]> {
    return this.http.get<Panel[]>(`${this.baseUrl}/${siteId}/groups/${groupId}/panels`)
  }

  getSchedules(groupId: string, siteId: string): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(`${this.baseUrl}/${siteId}/groups/${groupId}/schedules`);
  }

  getTelemetry(groupId: string, siteId: string): Observable<GroupTelemetry> {
    return this.http.get<GroupTelemetry>(`${this.baseUrl}/${siteId}/groups/${groupId}/telemetry`);
  }

  getGroupTelemetry(groupId: string, siteId: string, data: TelemetryQuery): Observable<GroupTelemetry> {
    return this.http.post<GroupTelemetry>(`${this.baseUrl}/${siteId}/groups/${groupId}/telemetry`, data);
  }

  getChargerStatusOverview(groupId: string, siteId: string): Observable<ChargerStatuses[]> {
    return this.http.get<ChargerStatuses[]>(`${this.baseUrl}/${siteId}/groups/${groupId}/charger-statuses`)
  }

  getGroupActivePower(groupId: string, siteId: string): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/${siteId}/groups/${groupId}/active-power`)
  }

  getGroupAverageChargeDuration(groupId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/groups/${groupId}/average-charge-duration`, data);
  }

  getGroupEnergyUsage(groupId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/groups/${groupId}/energy-usage`, data);
  }

  getGroupSessionCount(groupId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/groups/${groupId}/session-count`, data);
  }

  getGroupUptime(groupId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/groups/${groupId}/uptime`, data);
  }

  /**
   * Indicates if the group has any chargers with a fault as their last reported status.
   * @param siteId The site identifier.
   * @param groupId The group identifier.
   * @returns true if the group has any chargers with a fault as their last reported status otherwise false.
   */
  getGroupHasFaults(groupId: string, siteId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/${siteId}/groups/${groupId}/faulted`);
  }

  /**
   * Returns a list of chargers with a fault as their last reported status for this group.
   * @param siteId The site identifier.
   * @param groupId The group identifier.
   * @returns an array of chargers with a fault as their last reported status.
   */
  getGroupFaults(groupId: string, siteId: string): Observable<Fault[]> {
    return this.http.get<Fault[]>(`${this.baseUrl}/${siteId}/groups/${groupId}/faults`);
  }

  getGroupConnectivity(groupId: string, siteId: string) {
    return this.http.get<boolean>(`${this.baseUrl}/${siteId}/groups/${groupId}/connectivity`);
  }

  getHistoricalGroupConnectivity(groupId: string, siteId: string, data: TelemetryQuery) {
    return this.http.post<GroupConnectivity[]>(`${this.baseUrl}/${siteId}/groups/${groupId}/connectivity`, data);
  }

  getGroupStations(siteId: string, groupId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${siteId}/groups/${groupId}/stations`)
  }
}
