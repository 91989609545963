import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { GroupsService } from '@core-shared/services/groups/groups.service';
import { PanelsService } from '@core-shared/services/panels/panels.service';
import { SitesService } from '@core-shared/services/sites/sites.service';
import { forkJoin, map, Observable } from 'rxjs';
import { HttpRequestState, httpRequestStates } from 'ngx-http-request-state';

@Component({
  selector: 'app-fault-indicator',
  templateUrl: './fault-indicator.component.html',
  styleUrls: ['./fault-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaultIndicatorComponent implements OnChanges {
  @Input() type!: string;
  @Input() siteId!: string;
  @Input() groupId!: string;
  @Input() panelId!: string;

  hasFaults$!: Observable<HttpRequestState<boolean>>;
  faultMessage: string = 'Faulted';
  constructor(private siteService: SitesService,
    private groupService: GroupsService,
    private panelService: PanelsService) { }

  ngOnChanges(): void {
    switch (this.type) {
      case 'Site':
        if (this.siteId) {
          this.hasFaults$ = forkJoin([
            this.siteService.getSiteHasFaults(this.siteId),
            this.siteService.getSiteConnectivity(this.siteId),
          ]).pipe(map(([fault, connectivity]) => {
            this.determineFault(fault, connectivity)
            return fault || !connectivity
          })).pipe(httpRequestStates())
        }
        break;
      case 'Group':
        if (this.groupId && this.siteId) {
          this.hasFaults$ = forkJoin([
            this.groupService.getGroupHasFaults(this.groupId, this.siteId),
            this.groupService.getGroupConnectivity(this.groupId, this.siteId),
          ]).pipe(map(([fault, connectivity]) => {
            this.determineFault(fault, connectivity)
            return fault || !connectivity
          })).pipe(httpRequestStates())
        }
        break;
      case 'Panel':
        this.faultMessage = 'Faulted';
        if (this.panelId && this.siteId) {
          this.hasFaults$ = this.panelService.getPanelHasFaults(this.panelId, this.siteId)
            .pipe(httpRequestStates());
        }
        break;
      default:
        break;
    }
  }

  determineFault(fault: boolean, connectivity: boolean) {
    if (fault && !connectivity) {
      this.faultMessage = 'Faulted & Disconnected'
    } else if (fault) {
      this.faultMessage = 'Faulted'
    } else if (!connectivity) {
      this.faultMessage = 'Disconnected'
    }
  }
}
