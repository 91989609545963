import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@global/services/auth/auth.service';
import { NotificationService } from '@global/services/notification/notification.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  activeIndex = 0;
  email!: UntypedFormControl;
  password!: UntypedFormControl;
  userLoginForm!: UntypedFormGroup;
  otp: string = '';
  showPassword = false;
  constructor(public auth: AuthService, private notify: NotificationService) {
  }

  ngOnInit(): void {
    this.checkLoggedIn();
    this.createForm();
  }

  createForm() {
    this.email = new UntypedFormControl('', [Validators.required]);
    this.password = new UntypedFormControl('', [Validators.required]);
    this.userLoginForm = new UntypedFormGroup({
      email: this.email,
      password: this.password
    });
  }

  submitLogin = (formValue: any) => {
    return this.auth.login(formValue.email, formValue.password).catch(async (err: HttpErrorResponse) => {
      if (err.error.message) {
        this.notify.showError(`Error: Invalid username or password`);
      } else {
        this.notify.showError('Error: Something went wrong');
      }
      await this.auth.logout();
      this.activeIndex -= 1;
    }).finally(() => {
      this.activeIndex += 1;
    });
  };

  onOtpChange(otp: string) { 
    //backspace usage
    if (otp.length < this.otp.length) {
      let ip: any = document.querySelectorAll('.otp-input')[otp.length];
      ip.select();
      ip.autofocus;
    }
    this.otp = otp;
  }

  async submitTFA() {
    if (this.otp.length < 6) {
      this.notify.showError('Invalid Entry');
    } else {
      await this.auth.tfaLogin(this.otp)
      .catch((err: HttpErrorResponse) => {
          this.notify.showError(`Error: ${err.error}`);
      });
    }
  }


  /**
   * Checks to see if user is authenticated and if so directs them to the appropriate pages. Also doesn't allow user to come back to login page unless they logout
   */
  private checkLoggedIn(): void {
    if (this.auth.isAuthenticated) {
      const orgAndSiteList = this.auth.orgAndSiteList;

      if (this.auth.change_password) {
        this.auth.navToChangePassword();
      } else if (!this.auth.eula) {
        this.auth.navToEula();
      } else if (orgAndSiteList.orgs.length > 1 || (!orgAndSiteList.firstOrg && !orgAndSiteList.firstSite)) {
        this.auth.navToOrganizationList();
      } else if (orgAndSiteList.sites.length > 1 && orgAndSiteList.firstOrg) {
        this.auth.navToSiteList(orgAndSiteList.firstOrg)
      } else {
        if (orgAndSiteList.firstOrg && orgAndSiteList.firstSite) {
          this.auth.navToSiteDashboard(orgAndSiteList.firstOrg, orgAndSiteList.firstSite);
        } else {
          this.auth.navToOrganizationList();
        }
      }
    } else {
      this.auth.logout().then(r => r);
    }

  }
}
