import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map, Observable } from 'rxjs';
import { Charger, ChargerStatuses } from '@core-shared/models/charger.model';
import { Panel, PanelAssignmentReturn, PanelTelemetry } from '@core-shared/models/panel.model';
import { Switch } from '@core-shared/models/switch.model';
import { Fault } from '@core-shared/models/fault.model';
import { TelemetryQuery } from '@core-shared/models/telemetry-query.model';
import { changeChargerStatuses } from '@core-shared/components/charger-status-modifier/charger-status-modfier';

@Injectable({
  providedIn: 'root'
})
export class PanelsService {
  baseUrl = `${environment.apiEndpoint}/sites`

  constructor(private http: HttpClient) { }

  get panelName(): string | null {
    return sessionStorage.getItem('panelName');
  }

  setPanelName(name: string) {
    sessionStorage.setItem('panelName', name);
  }

  getPanel(panelId: string, siteId: string): Observable<Panel> {
    return this.http.get<Panel>(`${this.baseUrl}/${siteId}/panels/${panelId}`)
  }

  getPanelBySerial(serialId: string, siteId: string): Observable<Panel> {
    return this.http.get<Panel>(`${this.baseUrl}/${siteId}/panels/serial/${serialId}`)
  }

  getZonePanels(zoneId: string, siteId: string): Observable<Panel> {
    return this.http.get<Panel>(`${this.baseUrl}/${siteId}/zones/${zoneId}/panels`)
  }

  getPanelOverview(panelId: string, siteId: string): Observable<Panel> {
    return this.http.get<Panel>(`${this.baseUrl}/${siteId}/panels/${panelId}/overview`)
  }

  addPanelToGroup(panelId: string, siteId: string, groupId: string, data: any): Observable<PanelAssignmentReturn> {
    return this.http.post<PanelAssignmentReturn>(`${this.baseUrl}/${siteId}/panels/${panelId}/groups/${groupId}`, data)
  }

  removePanelFromGroup(panelId: string, siteId: string, groupId: string): Observable<PanelAssignmentReturn> {
    return this.http.delete<PanelAssignmentReturn>(`${this.baseUrl}/${siteId}/panels/${panelId}/groups/${groupId}`)
  }

  updatePanelZone(panelId: string, siteId: string, zoneId: string, data: any): Observable<Panel> {
    return this.http.put<Panel>(`${this.baseUrl}/${siteId}/panels/${panelId}/zones/${zoneId}`, data)
  }

  removePanelFromZone(panelId: string, siteId: string, zoneId: string): Observable<Panel> {
    return this.http.delete<Panel>(`${this.baseUrl}/${siteId}/panels/${panelId}/zones/${zoneId}`)
  }

  assignPanelToGroup(panelId: string, siteId: string, data: any): Observable<PanelAssignmentReturn> {
    return this.http.post<PanelAssignmentReturn>(`${this.baseUrl}/${siteId}/panels/${panelId}/assign-panel`, data)
  }

  updatePanel(panel: Panel, siteId: string): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/${siteId}/panels/${panel.panelId}`, panel);
  }

  updatePanelGroup(panel: Panel, groupId: string): Observable<Panel> {
    return this.http.put<Panel>(`${this.baseUrl}/${panel.siteId}/panels/${panel.panelId}/groups/${groupId}`, {});
  }

  deletePanel(panelId: string, siteId: string): Observable<Panel> {
    return this.http.delete<Panel>(`${this.baseUrl}/${siteId}/panels/${panelId}`)
  }

  getSwitches(panelId: string, siteId: string): Observable<Switch[]> {
    return this.http.get<Switch[]>(`${this.baseUrl}/${siteId}/panels/${panelId}/switches`);
  }

  getChargers(panelId: string, siteId: string): Observable<Charger[]> {
    return this.http.get<Charger[]>(`${this.baseUrl}/${siteId}/panels/${panelId}/chargers`);
  }

  getChargerStatusOverview(panelId: string, siteId: string): Observable<ChargerStatuses[]> {
    return this.http.get<ChargerStatuses[]>(`${this.baseUrl}/${siteId}/panels/${panelId}/charger-statuses`)
  }

  getChargerStatusOverviewWithStatusOverride(panelId: string, siteId: string): Observable<ChargerStatuses[]> {
    return this.http.get<ChargerStatuses[]>(`${this.baseUrl}/${siteId}/panels/${panelId}/charger-statuses`)
    .pipe(
      map(x => changeChargerStatuses(x))
      )
  }

  getPanelActivePower(panelId: string, siteId: string): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/${siteId}/panels/${panelId}/active-power`)
  }

  getPanelAverageChargeDuration(panelId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/panels/${panelId}/average-charge-duration`, data);
  }

  getPanelEnergyUsage(groupId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/panels/${groupId}/energy-usage`, data);
  }

  getPanelSessionCount(groupId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/panels/${groupId}/session-count`, data);
  }

  getPanelUptime(panelId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/panels/${panelId}/uptime`, data);
  }

  /**
   * Indicates if the panel has any chargers with a fault as their last reported status.
   * @param siteId The site identifier.
   * @param panelId The panel identifier.
   * @returns true if the panel has any chargers with a fault as their last reported status otherwise false.
   */
  getPanelHasFaults(panelId: string, siteId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/${siteId}/panels/${panelId}/faulted`);
  }

  /**
   * Returns a list of chargers with a fault as their last reported status for this panel.
   * @param siteId The site identifier.
   * @param panelId The panel identifier.
   * @returns an array of chargers with a fault as their last reported status.
   */
  getPanelFaults(panelId: string, siteId: string): Observable<Fault[]> {
    return this.http.get<Fault[]>(`${this.baseUrl}/${siteId}/panels/${panelId}/faults`);
  }

  getPanelTelemetry(panelId: string, siteId: string, data: TelemetryQuery): Observable<PanelTelemetry> {
    return this.http.post<PanelTelemetry>(`${this.baseUrl}/${siteId}/panels/${panelId}/telemetry`, data);
  }

  getPanelStations(panelId: string, siteId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${siteId}/panels/${panelId}/stations`)
  }
}
