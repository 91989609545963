import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { ToolbarModule } from 'primeng/toolbar';
import { AuthInterceptor } from './services/auth/auth-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotificationService } from './services/notification/notification.service';
import { TimezoneInterceptorService } from './services/timezone/timezone-interceptor.service';

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    ToolbarModule
  ],
  exports: [FooterComponent]
})
export class GlobalModule {
  static forRoot(): ModuleWithProviders<GlobalModule> {
    return {
      ngModule: GlobalModule,
      providers: [
        NotificationService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptorService, multi: true}
      ]
    };
  }
}
