import { Pipe, PipeTransform } from '@angular/core';

@Pipe({

  name: 'kebab'

})

export class KebabCaseIdPipe implements PipeTransform {

  transform(value: string): any {
    return value.trim().replaceAll(' ', '-').toLowerCase()  
  }
}