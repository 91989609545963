import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Charger, ChargerConfigurationUpdateForm, ChargerIntegrations, ChargerIntegrationsUpdateDto, ChargerOCPP, ChargerOCPPUpdateForm, ChargerPowerTelemetry, ChargerStatusOld } from '@core-shared/models/charger.model';
import { HttpClient } from '@angular/common/http';
import { TelemetryQuery } from '@core-shared/models/telemetry-query.model';

@Injectable({
  providedIn: 'root'
})
export class ChargersService {
  baseUrl = `${environment.apiEndpoint}/sites`
  constructor(private http: HttpClient) { }

  get chargerName(): string | null {
    return sessionStorage.getItem('chargerName');
  }

  setChargerName(name: string) {
    sessionStorage.setItem('chargerName', name);
  }

  getCharger(siteId: string, chargerId: string): Observable<Charger> {
    return this.http.get<Charger>(`${this.baseUrl}/${siteId}/chargers/${chargerId}`)
  }

  getChargerOCPP(siteId: string, chargerId: string): Observable<ChargerOCPP> {
    return this.http.get<ChargerOCPP>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/ocpp`)
  }

  getChargerIntegrations(siteId: string, chargerId: string): Observable<ChargerIntegrations> {
    return this.http.get<ChargerIntegrations>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/integrations`)
  }

  getChargerStatus(siteId: string, chargerId: string): Observable<ChargerStatusOld> {
    return this.http.get<ChargerStatusOld>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/status`)
  }

  updateCharger(siteId: string, charger: ChargerConfigurationUpdateForm, chargerId: string): Observable<Charger> {
    return this.http.put<Charger>(`${this.baseUrl}/${siteId}/chargers/${chargerId}`, charger);
  }

  updateChargerOCPP(siteId: string, chargerId: string, charger: ChargerOCPPUpdateForm): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/ocpp`, charger);
  }

  updateChargerIntegrations(siteId: string, chargerId: string, charger: ChargerIntegrationsUpdateDto): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/integrations`, charger);
  }

  getChargerStatusHistory(chargerId: string, siteId: string, data: TelemetryQuery) : Observable<ChargerStatusOld[]> {
    return this.http.post<ChargerStatusOld[]>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/status`, data);
  }

  getChargerActivePower(chargerId: string, siteId: string): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/active-power`)
  }

  getChargerAverageChargeDuration(chargerId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/average-charge-duration`, data);
  }

  getChargerEnergyUsage(chargerId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/energy-usage`, data);
  }

  getChargerSessionCount(chargerId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/session-count`, data);
  }

  getChargerUptime(chargerId: string, siteId: string, data: TelemetryQuery): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/uptime`, data);
  }

  getChargerPowerTelemetry(chargerId: string, siteId: string, data: TelemetryQuery): Observable<ChargerPowerTelemetry> {
    return this.http.post<ChargerPowerTelemetry>(`${this.baseUrl}/${siteId}/chargers/${chargerId}/power-telemetry`, data);
  }
}
