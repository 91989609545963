import { ModuleWithProviders, NgModule } from '@angular/core';

import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog'
import { DialogService } from 'primeng/dynamicdialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { GMapModule } from 'primeng/gmap';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast'
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

@NgModule({
    declarations: [],
    exports: [
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CheckboxModule,
        ChipModule,
        ConfirmPopupModule,
        ConfirmDialogModule,
        ContextMenuModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        DynamicDialogModule,
        GMapModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextareaModule,
        InputTextModule,
        MenuModule,
        MultiSelectModule,
        PanelModule,
        ProgressSpinnerModule,
        SelectButtonModule,
        TableModule,
        ToastModule,
        TooltipModule,
        VirtualScrollerModule,        
    ]
})
export class PrimengModule {
    constructor() { }

    static forRoot(): ModuleWithProviders<PrimengModule> {
        return {
            ngModule: PrimengModule,
            providers: [
                DialogService,
                ConfirmationService
            ]
        };
    }
}
