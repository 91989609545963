
<div id="top-nav" class="top-nav">
  <img src="assets/images/logo.svg" alt="Epurpl logo">
  <p class="site-manager"></p>
</div>
<div class="forgot-password">

  <p-card>
    <h2>Forgot Password</h2>
    <p *ngIf="activeIndex == 0">To reset your password, please verify your email address</p>
    <div *ngIf="activeIndex == 0">
      <app-form-container
        *ngIf="forgotPasswordInitForm"
        [formGroup]="forgotPasswordInitForm"
        submitLabel="Verify"
        [submitFunc]="submitInit"
        [buttonPosition]="'center'"
        [hasReset]="false">
        <form [formGroup]="forgotPasswordInitForm">
          <app-form-input label="Email Address" [control]="email" [required]="true">
            <input type="text" [formControl]="email" [autocomplete]="false"/>
          </app-form-input>
        </form>
      </app-form-container>
      <p-footer>
      </p-footer>

    </div>

    <p *ngIf="activeIndex == 1">Enter new password and code sent to your mobile number</p>
    <div *ngIf="activeIndex == 1">
      <app-form-container
        *ngIf="forgotPasswordUpdateForm"
        [formGroup]="forgotPasswordUpdateForm"
        submitLabel="Submit"
        [submitFunc]="submitUpdate"
        [buttonPosition]="'center'"
        [hasReset]="false">
        <form [formGroup]="forgotPasswordUpdateForm">
          <app-form-input label="New Password" [control]="newPassword" [required]="true">
            <input [type]="showPassword? 'text': 'password'" [autocomplete]="false" [formControl]="newPassword"/>
            <span [class]="showPassword? 'eye pi pi-eye-slash': 'eye pi pi-eye'" (click)="showPassword = !showPassword"></span>
          </app-form-input>
          <app-form-input label="Verification Code" [control]="twilioVerify" [required]="true">
            <p-inputMask [formControl]="twilioVerify"  mask="999999" autocomplete="false"></p-inputMask>
          </app-form-input>
        </form>
      </app-form-container>
      <p-footer>
      </p-footer>
    </div>
  </p-card>
</div>
<app-footer></app-footer>
