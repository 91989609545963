import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Status } from '@core-shared/models/charger.model';

@Directive({
  selector: '[appChargerStatus]'
})
export class ChargerStatusDirective {
  private status!: string;
  chargerStatus = Status;
  private ref!: EmbeddedViewRef<any>;
  @Input()
  set appChargerStatus(value: string) {
    this.status = value
    this.process();
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private element: ElementRef, private renderer: Renderer2) { }
  process() {
    if (!this.status) {
      this.show();
      return;
    }

    switch(this.status) {
      case this.chargerStatus.Charging:
        this.addClassToElement('success-bg-color');
        break;
      case this.chargerStatus.Preparing:
        this.addClassToElement('success-bg-color')
        break;
      case this.chargerStatus.Finishing:
        this.addClassToElement('success-bg-color')
        break;
      case this.chargerStatus.Available:
        this.addClassToElement('info-bg-color')
        break;
      case this.chargerStatus.Disabled:
        this.addClassToElement('warn-bg-color')
        break;
      case this.chargerStatus.Uncommissioned:
        this.addClassToElement('default-color')
        break;
      case this.chargerStatus.Faulted:
        this.addClassToElement('danger-bg-color')
        break;
      case this.chargerStatus.Charged:
        this.addClassToElement('unavailable-bg-color')
        break;
      case this.chargerStatus.SuspendedEV:
        this.addClassToElement('unavailable-bg-color')
        break;
      case this.chargerStatus.SuspendedEVSE:
        this.addClassToElement('unavailable-bg-color')
        break;
      case this.chargerStatus.Unknown:
        this.addClassToElement('unknown-bg-color')
        break;
    }

  }

  addClassToElement(cl: string) {
    this.renderer.addClass(this.element.nativeElement.parentElement, cl);
  }

  show() {
    if (this.ref) {
      return;
    }
    this.ref = this.viewContainer.createEmbeddedView(this.templateRef);
  }

}
