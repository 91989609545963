import { Injectable } from '@angular/core';
import  { Observable, Subject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private subject = new Subject<any>();

  refreshBreadcrumb() {
    this.subject.next({});
  }

  getEvent(): Observable<any> {
    return this.subject.asObservable();
  }
}
