import { ChargerStatuses, Status } from "@core-shared/models/charger.model";

export function changeChargerStatuses(chargedStatuses: ChargerStatuses[]): ChargerStatuses[] {
  chargedStatuses.forEach(x => assignStatus(x))
  return chargedStatuses;
}

export function assignStatus(chargerStatus: ChargerStatuses):ChargerStatuses  {
  let status: Status;
  if (chargerStatus.commissioned === false) {
    status = Status.Uncommissioned;
  } else if (chargerStatus.enabled === false) {
    status = Status.Disabled;
  } else if (chargerStatus.statuses[0]?.status === Status.SuspendedEV) {
    status = Status.Charged;
  } else {
    status = chargerStatus.statuses[0]?.status as Status ?? Status.Unknown;
  }

  chargerStatus.statusOverride = status;

  return chargerStatus;
}
