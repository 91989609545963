import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@global/services/auth/auth.service';
import { NotificationService } from '@global/services/notification/notification.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  email!: UntypedFormControl;
  newPassword!: UntypedFormControl;
  twilioVerify!: UntypedFormControl;
  forgotPasswordInitForm!: UntypedFormGroup;
  forgotPasswordUpdateForm!: UntypedFormGroup;
  activeIndex = 0;
  showPassword = false;
  constructor(public auth: AuthService, private notify: NotificationService) { }

  ngOnInit(): void {
    this.createForm()
  }

  createForm() {
    this.email = new UntypedFormControl('', [Validators.required, Validators.email]);
    this.newPassword = new UntypedFormControl('', [Validators.required]);
    this.twilioVerify = new UntypedFormControl('', [Validators.required]);

    this.forgotPasswordInitForm = new UntypedFormGroup({
      username: this.email
    });

    this.forgotPasswordUpdateForm = new UntypedFormGroup({
      newPassword: this.newPassword,
      twilioVerify: this.twilioVerify
    })
  }

  submitInit = (formValue: any) => {
    return this.auth.verifyEmail(formValue)
    .finally(() => {
      this.activeIndex += 1;
    });
  }

  submitUpdate = (formValue: any) => {
    const data = {
      username: this.email.value,
      newPassword: formValue.newPassword,
      twilioVerify: formValue.twilioVerify
    }
    return this.auth.forgotPasswordUpdate(data)
    .then(() => {
      this.notify.showSuccess('Password Reset Successful');
      this.auth.navToLogin();
    })
    .catch(() => {
      this.notify.showError('Reset Password Error. Please try later');
    })
    .finally(() => {
      this.activeIndex = 0;
      this.clearForm();
    })
  }

  clearForm() {
    this.forgotPasswordInitForm.reset();
    this.forgotPasswordUpdateForm.reset();
    this.forgotPasswordInitForm.controls.username.setErrors(null);
    this.forgotPasswordUpdateForm.controls.newPassword.setErrors(null);
    this.forgotPasswordUpdateForm.controls.twilioVerify.setErrors(null);
  }
}
