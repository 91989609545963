import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { eula } from '../../models/user-initial-setup.model';
import { Password, User } from '../../models/user.model'
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserInitialSetupService {
  baseUrl = `${environment.apiEndpoint}/users`
  constructor(private http: HttpClient) { }

  getEula(): Promise<any> {
    return lastValueFrom(this.http.get<any>(`${this.baseUrl}/eula`));
  }

  acceptEula(data: eula): Promise<any> {
    return lastValueFrom(this.http.post(`${this.baseUrl}/eula`, data));
  }

  changePassword(data: Password): Promise<any> {
    return lastValueFrom(this.http.put(`${this.baseUrl}/change-password`, data));
  }

  /**
   * Gets the logged in user's profile information
   * @returns user model of the logged in user.
   */
  getUserProfile(): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/profile`);
  }
}
