import { Injectable } from '@angular/core';
import { catchError, distinctUntilChanged, iif, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivateChild, UrlTree } from '@angular/router';
import { SitesService } from '../sites/sites.service';
interface Params {
  organizationId: string;
  siteId: string;
  groupId: string;
  panelId: string;
  chargerId: string;
}
@Injectable({
  providedIn: 'root'
})
export class StateService implements CanActivateChild {
  readonly DEFAULT_TIME_ZONE = 'UTC';

  private organizationId$ = new ReplaySubject<string>(1);
  private siteId$ = new ReplaySubject<string>(1);
  private groupId$ = new ReplaySubject<string>(1);
  private panelId$ = new ReplaySubject<string>(1);
  private chargerId$ = new ReplaySubject<string>(1);
  private sideMenu$ = new ReplaySubject<any[]>(1);
  private params!: Params;

  static readonly ROUTE_DATA_SIDEMENU = 'sideMenu';
  constructor(
    private sitesService: SitesService,
  ) { }

  get organizationId() {
    return this.organizationId$.asObservable();
  }

  get siteId() {
    return this.siteId$.asObservable();
  }

  get groupId() {
    return this.groupId$.asObservable();
  }

  get panelId() {
    return this.panelId$.asObservable();
  }

  get chargerId() {
    return this.chargerId$.asObservable();
  }

  get sideMenu() {
    return this.sideMenu$.asObservable();
  }
  get siteTimezone(){
    return sessionStorage.getItem("timeZone") ?? this.DEFAULT_TIME_ZONE;
  }
  setSiteTimezone(timeZone = this.DEFAULT_TIME_ZONE){
    sessionStorage.setItem('timeZone', timeZone)
  }

  /**
   * Listens for changes to siteId. If siteId changes then a call is made to get the timezone
   * for the site. Any errors will result in 'UTC' being returned.
  */
  public get siteTimeZone$() {
    return this.siteId$.asObservable().pipe(
      distinctUntilChanged(),
      switchMap((siteId: string) =>
        iif(
          () => !!(siteId),
          this.sitesService.getSiteTimezone(siteId).pipe(
            catchError(() => of(this.DEFAULT_TIME_ZONE)),
          ),
          of(this.DEFAULT_TIME_ZONE))),
    );
  }

  canActivateChild(r: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const data = r.data;
    this.params = r.params as Params;
    if (this.params.organizationId) {
      this.organizationId$.next(this.params.organizationId);
    }
    if (this.params.siteId) {
      this.siteId$.next(this.params.siteId);
    }
    if (this.params.groupId) {
      this.groupId$.next(this.params.groupId);
    }
    if (this.params.panelId) {
      this.panelId$.next(this.params.panelId);
    }
    if (this.params.chargerId) {
      this.chargerId$.next(this.params.chargerId);
    }
    if (data[StateService.ROUTE_DATA_SIDEMENU]) {
      this.sideMenu$.next(data[StateService.ROUTE_DATA_SIDEMENU])
    }
    return true;
  }
}
