import { FormControl } from "@angular/forms";

export interface Charger {
  id: string;
  switchId?: string;
  panelId: string;
  siteId?: string;

  name: string;
  circuit: string;
  model: string;
  serial: string;
  voltageClass: string;
  frameSize: number;
  aicRating: number;
  generation: number;
  position: number;
  installDate: string;
  firmware: string;

  phaseA: boolean;
  phaseB: boolean;
  phaseC: boolean;

  powerWire: string;
  groundWire: string;
  tripRating: number;

  commissioned: boolean;
  enabled: boolean;
  prioritized: boolean;
  lifetimeEnergy?: number;
  manufacturer: string;

  // instantTripThreshold?: number;
  // fastTrip?: boolean;
  // longTripTime?: number;
  // shortTripI2TState?: boolean;
  // shortTripThreshold?: number;
  // shortTripTime?: number;
  status?: string;

  ocppEnabled: boolean;
  ocppEndpoint: string;
  ocppChargerId: string;
  ocppUsername?: string;
  ocppPassword?: string;
  ocppAuthType?: string;

  powerLimit: number;
  longitude?: number;
  latitude?: number;
}

export interface ChargerOCPP {
  switchId: string | null;
  ocppEnabled: boolean | null;
  ocppEndpoint: string | null;
  ocppChargerId: string | null;
  ocppAuthType: string | null;
  ocppUsername: string | null;
  ocppPassword: string | null;
}

export interface ChargerIntegrations {
  integrationId: string | null;
  switchId?: string;
  siteId?: string;
}

// Use this model for supporting current ChargerStatus and HistoricalChargerStatus calls
export interface ChargerStatusOld {
  chargerId: string;
  time: any;
  status: string;
  charging: boolean;
  connected: boolean;
  faulted: boolean;
  fault: string;
  enabled: boolean;
  commissioned: boolean;
}

export interface ChargerStatuses {
  siteId: string;
  siteName: string;
  groupId: string;
  groupName: string;
  panelId: string;
  panelName: string;
  chargerId: string;
  chargerName: string;
  commissioned: boolean;
  enabled: boolean;
  // duplicate status entry for simplifying status options
  statusOverride?: string;
  statuses: ChargerStatus2[]
}

//TODO: reename to chargerStatus eventually
export interface ChargerStatus2 {
  time: any;
  status: string;
  charging: boolean;
  connected: boolean;
  faulted: boolean;
  fault: string;
}

export enum Status {
  Available = 'Available',
  Faulted = 'Faulted',
  Charging = 'Charging',
  Preparing = 'Preparing',
  Finishing = 'Finishing',
  Charged = 'Charged',
  Uncommissioned = 'Uncommissioned',
  Disabled = 'Disabled',
  SuspendedEV = 'SuspendedEV',
  SuspendedEVSE = 'SuspendedEVSE',
  Unknown = 'Unknown'
};

export interface ChargerStatusSummary {
  total: number;
  charging: number;
  available: number;
  faulted: number;
  disabled: number;
  unknown: number;
}

export interface ChargerPowerTelemetry {
  id: string;
  name: string;
  power: [[x: number, y: number]] | [];
  rate: [[x: number, y: number]] | [];
}

export interface ChargerConfigurationUpdateForm {
  name: FormControl<string | null>;
  circuit: FormControl<string | null>;
  location: FormControl<string | null>;
  longitude: FormControl<number | null>;
  latitude: FormControl<number | null>;
  powerLimit: FormControl<number | null>;
  enabled: FormControl<boolean | null>;
  prioritized: FormControl<boolean | null>;
  serial: FormControl<string | null>;
  model: FormControl<string | null>;
}

export interface ChargerConfigurationUpdate {
  name: string | null;
  circuit: string | null;
  location: string | null;
  longitude: number | null;
  latitude: number | null;
  powerLimit: number | null;
  enabled: boolean | null;
  prioritized: boolean | null;
  serial: string | null;
  model: string | null;
}



export interface ChargerOCPPUpdateDto {
  ocppEnabled: boolean | null;
  ocppEndpoint: string | null;
  ocppChargerId: string | null;
  ocppAuthType: string | null;
  ocppUsername: string | null;
  ocppPassword: string | null;
}

export interface ChargerOCPPUpdateForm {
  ocppEnabled: FormControl<boolean | null>;
  ocppEndpoint: FormControl<string | null>;
  ocppChargerId: FormControl<string | null>;
  ocppAuthType: FormControl<string | null>;
  ocppUsername: FormControl<string | null>;
  ocppPassword: FormControl<string | null>;
}

export interface ChargerIntegrationsUpdateDto {
  integrationId: string | null;
}

export interface ChargerIntegrationsUpdateForm {
  integrationId: FormControl<string | null>;
}
