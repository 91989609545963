import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CanActivate, CanActivateChild, CanLoad, Router} from '@angular/router';
import {environment} from '@environments/environment';
import { Permission, TfaTokenResponseModel, TokenContents } from './models/token-response.model';
import { lastValueFrom } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { ForgotPasswordModel } from './models/forgot-password.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanLoad, CanActivate, CanActivateChild {
  refreshTokenTimeout: any;
  constructor(private router: Router, private http: HttpClient) {
    this.startRefreshTokenTimer();
  }

  userLogin(u: string, p: string, headers?: HttpHeaders): Promise<any> {
    return lastValueFrom(this.http.post(`${environment.tokenEndpoint}`, {username: u, password: p}, {headers}))
  }

  twillo(authCode: string, headers?: HttpHeaders): Promise<any> {
    return lastValueFrom(this.http.post(`${environment.optEndpoint}`, JSON.stringify(authCode), {headers}))
  }

  forgotPassword(data: ForgotPasswordModel, headers?: HttpHeaders): Promise<any> {
    headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);
    return lastValueFrom(this.http.post(`${environment.apiEndpoint}/auth/forgot-password`, data, {headers}))
  }

  forgotPasswordUpdate(data: ForgotPasswordModel, headers?: HttpHeaders): Promise<any> {
    headers = new HttpHeaders().set('Authorization', `Bearer ${this.access_token}`)
    .set('X-Api-Key', environment.apiKey)
    return lastValueFrom(this.http.post(`${environment.apiEndpoint}/auth/forgot-password/change-password`, data, {headers}))
  }

  refresh(headers?: HttpHeaders): Promise<any> {
    return lastValueFrom(this.http.post(`${environment.refreshEndpoint}`, JSON.stringify(this.refresh_token), {headers}))
  }

  async login(u: string, p: string, headers?: HttpHeaders): Promise<void> {
    headers = new HttpHeaders().set('X-Api-Key', environment.apiKey);
    return this.userLogin(u, p, headers).then((resp) => {
      this.setAccessToken(resp);
      return resp;
    })
  }

  async verifyEmail(data: ForgotPasswordModel, headers?: HttpHeaders): Promise<void> {
    return this.forgotPassword(data, headers).then((resp) => {
      this.setAccessToken(resp);
      return resp;
    })
  }

  private startRefreshTokenTimer() {
    // set a timeout to refresh the token a minute before it expires
    if (this.access_token) {
      const timeout = (new Date(this.access_token_expiration).getTime() * 1000) - Math.floor((new Date()).getTime()) - (60 * 1000);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
    }
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  async logout(redirect: boolean = true): Promise<void> {
    sessionStorage.clear();
    this.stopRefreshTokenTimer();
    if (redirect) {
      await this.navToLogin();
    }
  }

  async tfaLogin(authCode: string): Promise<TfaTokenResponseModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.access_token}`)
    .set('X-Api-Key', environment.apiKey)
    return this.twillo(authCode, headers).then((resp) => {
      this.setAccessToken(resp.accessToken);
      this.setRefreshToken(resp.refreshToken);
      this.startRefreshTokenTimer();
      this.setUserId();

      const orgAndSiteList = this.orgAndSiteList;

      if (this.change_password) {
        this.navToChangePassword();
      } else if (!this.eula) {
        this.navToEula();
      } else if (this.superUser) {
        this.navToOrganizationList();
      } else if (orgAndSiteList.orgs.length > 1) {
        this.navToOrganizationList();
      } else if (orgAndSiteList.sites.length > 1 && orgAndSiteList.firstOrg) {
        this.navToSiteList(orgAndSiteList.firstOrg)
      } else if (orgAndSiteList.orgs.length === 1 && orgAndSiteList.sites.length === 1 && orgAndSiteList.firstOrg && orgAndSiteList.firstSite){
        this.navToSiteDashboard(orgAndSiteList.firstOrg, orgAndSiteList.firstSite);
      } else {
        this.navToOrganizationList();
      }
      return resp;
    });
  }

  refreshToken(): Promise<void> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.access_token}`);
    headers.append('X-Api-Key', environment.apiKey)
    return this.refresh(headers)
    .then((resp) => {
      this.setAccessToken(resp.accessToken);
      this.setRefreshToken(resp.refreshToken);
      this.startRefreshTokenTimer();
      this.setUserId();
    }).catch(() => {
      this.logout();
    })
  }


  async navToLogin(): Promise<void> {
    await this.router.navigateByUrl('login');
  }

  async navToDashboard(orgId: string, siteId: string): Promise<void> {
    await this.router.navigate(['core/organization/' +  orgId + '/sites/' + siteId + '/dashboard']);
  }

  async navToEula(): Promise<void> {
    await this.router.navigate(['core/eula']);
  }

  async navToChangePassword(): Promise<void> {
    await this.router.navigate(['core/user/change-password']);
  }

  async navToOrganizationList(): Promise<void> {
    await this.router.navigate(['core/organization']);
  }

  async navToSiteList(orgId: string): Promise<void> {
    await this.router.navigate(['core/organization/' +  orgId + '/sites']);
  }

  async navToSiteDashboard(orgId: string, siteId: string): Promise<void> {
    await this.router.navigate(['core/organization/' +  orgId + '/sites/' + siteId + '/dashboard']);
  }

  get user_id(): string {
    const userId = sessionStorage.getItem('user_id');
    return userId !== null ? userId : '';
  }

  get access_token(): string {
    const accessToken = sessionStorage.getItem('accessToken');
    return accessToken !== null ? accessToken : '';
  }

  get refresh_token(): string {
    const refreshToken = sessionStorage.getItem('refreshToken');
    return refreshToken !== null ? refreshToken : '';
  }

  get access_token_expiration() {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.exp;
  }

  get change_password() {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.user.changePassword;
  }

  get eula() {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.user.eula;
  }

  get orgList(): Permission {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.permissions.organization ?? {};
  }

  get siteList(): Permission {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.permissions.site ?? {};
  }

  /**
   * Return user's permissions for a specific site from the token.
   * @param siteId 
  */
  getSitePermissions(siteId: string): number[] {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.permissions.site?.[siteId] ?? [];
  }

  /**
   * Return user's permissions for a specific organization from the token.
   * @param orgId 
  */
  getOrganizationPermissions(orgId: string): number[] {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.permissions.organization?.[orgId] ?? [];
  }

  get orgAndSiteList() {
    const decodedToken: TokenContents = jwt_decode(this.access_token);

    const orgList = Object.keys(decodedToken.permissions.organization ?? {});
    const siteList = Object.keys(decodedToken.permissions.site ?? {});

    return {
      sites: siteList,
      orgs: orgList,
      firstOrg: orgList.shift() ?? null,
      firstSite: siteList.shift() ?? null
    }
  }

  get isAuthenticated(): boolean {
    return this.access_token && this.user_id ? true : false;
  }

  get superUser(): boolean {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.user.superUser ? decodedToken.user.superUser : false;
  }

  private setUserId() {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    sessionStorage.setItem('user_id', decodedToken.user.id);
  }

  private setAccessToken(token: string) {
    sessionStorage.setItem('accessToken', token);
  }

  private setRefreshToken(token: string) {
    sessionStorage.setItem('refreshToken', token);
  }

  async canActivate(): Promise<boolean> {
    if (!this.isAuthenticated) {
      await this.logout();
      return false;
    }
    if (!this.eula) {
      return false;
    }
    return true;
  }

  async canLoad(): Promise<boolean> {
    if (!this.isAuthenticated) {
      await this.logout();
      return false;
    }
    return true;
  }

  async canActivateChild(): Promise<boolean> {
    if (!this.isAuthenticated) {
      await this.logout();
      return false;
    }
    return true;
  }
}
