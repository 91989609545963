<div class="main">
  <span>
      <input
        [style.width]="width"
        [id]="label"
        [placeholder]="placeHolder"
        (keydown.enter)="handleEnterKey(input)"
        (keydown.escape)="input.readOnly = true"
        aria-label="press enter to"
        #input
        readonly
        [(ngModel)]="value"
        pInputText
        maxlength="64">
    <label *ngIf="showLabel" class="float low" [for]="label">{{label}}</label>
  </span>
  <button
    autofocus
    class="edit"
    pButton
    icon="pi pi-pencil"
    *ngIf="input.readOnly && canEdit"
    (click)="input.readOnly = false"></button>
  <div *ngIf="!input.readOnly" class="change-buttons">
    <button
      pButton
      icon="pi pi-times"
      aria-label="discard changes"
      class="clear"
      (click)="input.value = originalValue; input.readOnly = true">
    </button>
    <button
      pButton
      icon="pi pi-check"
      aria-label="save changes"
      class="save"
      (click)="input.readOnly = true; save()"></button>
  </div>
</div>
