<fieldset [ngStyle]="style" (keydown.enter)="submit()">
  <div class="form-content flex-column">
    <ng-content></ng-content>
    <app-form-error [control]="form"></app-form-error>
  </div>
  <div *ngIf="!customButton" class="button-group" [style.justify-content]="buttonPosition">
    <button id="custom-check-button" [disabled]="!form.valid || form.pristine || switchEditButtonDisable" (click)="submit()" pButton [label]="submitLabel"
              type="submit" *ngIf="switchEditButton else regular"></button>
    <ng-template #regular>
      <button id="check-button" [disabled]="!form.valid || form.pristine" (click)="submit()" pButton [label]="submitLabel"
              type="submit"></button>
    </ng-template>
    <button id="times-button" *ngIf="hasReset" class="p-button-secondary" [disabled]="form.pristine" pButton (click)="reset()"
            [label]="resetLabel" type="reset"></button>
  </div>
</fieldset>
