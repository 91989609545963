import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Organization } from '@core-shared/models/organization.model';
import { Site, SiteConfigurationUpdate } from '@core-shared/models/site.model';
import { Panel } from '@core-shared/models/panel.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  baseUrl = `${environment.apiEndpoint}/organizations`
  constructor(private http: HttpClient) { }

  get organizationName(): string | null {
    return sessionStorage.getItem('organizationName');
  }

  setOrganizationName(name: string) {
    sessionStorage.setItem('organizationName', name);
  }

  getOrganization(organizationId: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.baseUrl}/${organizationId}`);
  }

  getOrganizations(): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.baseUrl);
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(`${this.baseUrl}/${organization.organizationId}`, organization);
  }

  getSites(organizationId: string): Observable<Site[]> {
    return this.http.get<Site[]>(`${this.baseUrl}/${organizationId}/sites`);
  }

  getUserSites(organizationId: string): Observable<Site[]> {
    return this.http.get<Site[]>(`${this.baseUrl}/${organizationId}/user-sites`);
  }

  updateSite(organizationId: string, siteId: string,  site: SiteConfigurationUpdate):Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/${organizationId}/sites/${siteId}`, site);
  }

  // TODO: verify call and add type for data once Scott implements it in the backend -> See usage and write corresponding model
  updateOrgPanel(data: any): Observable<null> {
    return this.http.put<null>(`${environment.apiEndpoint}/sites/${data.siteId}/panels/${data.panelId}/groups/${data.groupId}`, data);
  }

  createOrganization(data: Organization): Observable<Organization> {
    return this.http.post<Organization>(`${this.baseUrl}`, data);
  }

  unassignedPanels(): Observable<Panel[]> {
    return this.http.get<Panel[]>(`${environment.apiEndpoint}/panels/unassigned`);
  }

  createSite(site: Site, orgId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/${orgId}/sites`, site)
  }

  getOrganizationStations(orgId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${orgId}/stations`)
  }
}
