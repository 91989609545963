<highcharts-chart
  [Highcharts]="Highcharts"
  [style]="style"
  [constructorType]="chartConstructor"
  [options]="chartOptions"
  [callbackFunction]="chartCallback"
  [(update)]="updateFlag"
  [oneToOne]="oneToOneFlag"
  [style]="styleSettings"
></highcharts-chart>
